import React from 'react';
import PropTypes from 'prop-types';
import SponsorsMobile from 'components/sponsors/mobile';
import HomepageSponsorsDesktop from './desktop';

const HomepageSponsors = ({ isMobile, sponsors }) => {
  return isMobile ? (
    <SponsorsMobile sponsors={sponsors} sponsorsPerPage={4} />
  ) : (
    <HomepageSponsorsDesktop sponsors={sponsors} />
  );
};

HomepageSponsors.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default HomepageSponsors;
