import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { FIXTURE_PROP_TYPE } from 'lib/prop-types/fixture';
import { Trans } from 'lib/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { Cushion, Grid, MaxWidth } from '@pitchero/react-ui';
import Matches from './matches';
import MoreLink from '../more-link';

const HomepageMatches = ({ fixtures, results, club, isMobile }) => {
  return (
    <MaxWidth maxWidth={1200} style={{ width: '100%' }} center>
      <Grid
        columns={isMobile ? '1fr' : 'repeat(auto-fit, minmax(0, 1fr))'}
        columnGap="20px"
        alignContent="center"
        style={{ width: '100%', maxWidth: '1200px' }}
      >
        {fixtures && fixtures.length > 0 && (
          <Matches
            matches={fixtures.slice(0, 3) || []}
            club={club}
            title="Upcoming Matches"
            isMobile={isMobile}
          />
        )}
        {results && results.length > 0 && (
          <Matches
            matches={results.slice(0, 3) || []}
            club={club}
            title="Recent Results"
            isMobile={isMobile}
          />
        )}
      </Grid>
      <Cushion
        horizontal="medium"
        style={{ margin: '0 auto', width: '100%', boxSizing: 'border-box' }}
        component="div"
      >
        <MoreLink club={club} route="matches">
          <Trans i18nKey="common:view_all_matches">View All Matches</Trans>
        </MoreLink>
      </Cushion>
    </MaxWidth>
  );
};

HomepageMatches.propTypes = {
  fixtures: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  results: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  club: CLUB_PROP_TYPES.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

HomepageMatches.defaultProps = {
  fixtures: [],
  results: [],
};

export default HomepageMatches;
