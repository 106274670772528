import { FlexContainer, Rectangle, Typography } from '@pitchero/react-ui';
import ClubRanking from 'components/club-frame/header/ranking';
import IconWithTheme from 'components/icon-with-theme';
import FacebookIcon from 'icons/Facebook';
import InstagramIcon from 'icons/Instagram';
import TwitterIcon from 'icons/Twitter';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import React from 'react';

const HomepagePromotedLinks = ({ club }) => {
  const { facebookPage, twitterAccount, instagramAccount, rank, rankGroup } = club;

  if (!facebookPage && !twitterAccount && !instagramAccount) {
    return null;
  }

  return (
    <Rectangle fill="#fff" component="div">
      <Rectangle fill="#000" component="div">
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          style={{ padding: '16px', gap: '10px' }}
        >
          <Typography preset="title" size={14} color="white">
            <Trans i18nKey="common:follow_the_club">Follow The Club</Trans>
          </Typography>
          <FlexContainer justifyContent="center" style={{ gap: '20px' }}>
            {facebookPage && (
              <a href={facebookPage} title="facebook" aria-label="Club Facebook">
                <IconWithTheme>
                  <FacebookIcon color="#fff" size={24} />
                </IconWithTheme>
              </a>
            )}
            {twitterAccount && (
              <a
                href={`https://twitter.com/${twitterAccount}`}
                title="twitter"
                aria-label="Club Twitter"
              >
                <IconWithTheme>
                  <TwitterIcon color="#fff" size={20} viewBox="0 0 1200 1200" />
                </IconWithTheme>
              </a>
            )}
            {instagramAccount && (
              <a href={instagramAccount} title="instagram" aria-label="Club Instagram">
                <IconWithTheme>
                  <InstagramIcon color="#fff" size={24} />
                </IconWithTheme>
              </a>
            )}
          </FlexContainer>
          <ClubRanking rank={rank} rankGroup={rankGroup} />
        </FlexContainer>
      </Rectangle>
    </Rectangle>
  );
};

HomepagePromotedLinks.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default HomepagePromotedLinks;
