import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, FlexContainer, Grid, Typography } from '@pitchero/react-ui';
import styled from 'styled-components';
import { Trans } from 'lib/i18n';

const FormSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  ${(props) => {
    if (props.result === 'W') {
      return `
        background-color: #56E475;
      `;
    }
    if (props.result === 'D') {
      return `
        background-color: #D9D9D9;
      `;
    }
    return `
        background-color: #FF5454;
        color: white;
      `;
  }}
`;

const FormGuide = ({ data }) => {
  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      style={{ gap: '16px', maxWidth: '100%' }}
    >
      <Typography size={18} color="#000" weight={600}>
        <Trans i18nKey="teams:statistics_form">Form</Trans>
      </Typography>
      <Grid
        columns="repeat(auto-fit, 35px)"
        columnGap="8px"
        alignItems="center"
        justifyContent="center"
        rowGap="10px"
        style={{ width: '100%', height: '40px', overflow: 'hidden' }}
      >
        {data.form.map((result, index) => (
          // Index used as key as list is static, and result is non-unique
          // eslint-disable-next-line react/no-array-index-key
          <FormSquare key={index} result={result}>
            {result}
          </FormSquare>
        ))}
      </Grid>
      {data.ppg && (
        <Cushion vertical="xsmall" component="div">
          <Typography>
            <Trans i18nKey="teams:fixture_average_points_per_game">Average PPG:</Trans>
            <Typography weight="bold" style={{ paddingLeft: '5px' }}>
              {data.ppg}
            </Typography>
          </Typography>
        </Cushion>
      )}
    </FlexContainer>
  );
};

FormGuide.propTypes = {
  data: PropTypes.shape({ form: PropTypes.arrayOf(PropTypes.string), ppg: PropTypes.string }),
};

FormGuide.defaultProps = {
  data: { form: [], ppg: '0' },
};

export default FormGuide;
