import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@pitchero/react-ui';
import { openLoginPanel } from 'store/auth/actions';
import { Trans } from 'lib/i18n';

const LoginButton = ({ openPanel, translationKey, ...props }) => (
  <Button theme="primary" onClick={openPanel} {...props}>
    <Trans i18nKey={translationKey}>Login</Trans>
  </Button>
);

LoginButton.defaultProps = {
  translationKey: 'common:login',
};

LoginButton.propTypes = {
  openPanel: PropTypes.func.isRequired,
  translationKey: PropTypes.string,
};

export default connect(null, { openPanel: openLoginPanel })(LoginButton);
