import { Cushion, FlexContainer, Rectangle, Typography } from '@pitchero/react-ui';
import ClubLink from 'components/club-link';
import Table from 'components/table/shortened';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import useBreakpoint from 'lib/use-breakpoint';
import PropTypes from 'prop-types';
import React from 'react';
import MoreLink from '../more-link';

const HomepageTable = ({ data, club }) => {
  if (!data) return null;
  const isMobile = useBreakpoint();
  const table = data.tables?.[0];
  if (!table) return null;

  const content = (
    <ClubLink route="team.league_table" params={{ teamId: data.teamId, tableId: table.id }}>
      <a>
        <FlexContainer flexDirection="column" style={{ width: '100%', backgroundColor: '#fff' }}>
          <Rectangle fill="#000" component="div">
            <Cushion vertical="small">
              <Typography
                color="white"
                preset="title"
                align="center"
                size={isMobile ? 14 : 16}
                component="div"
              >
                {table.name}
              </Typography>
            </Cushion>
          </Rectangle>
          {table && (
            <Cushion horizontal={isMobile ? 'xsmall' : 'none'} component="div">
              <Table {...table} isV2 isMobile={isMobile} />
            </Cushion>
          )}
        </FlexContainer>
      </a>
    </ClubLink>
  );

  const moreLink = (
    <MoreLink
      club={club}
      route="team.league_table"
      params={{ teamId: data.teamId, tableId: table.id }}
    >
      <Trans i18nKey="common:view_full_table">View full table</Trans>
    </MoreLink>
  );

  if (isMobile) {
    return (
      <div>
        {content}
        <Cushion horizontal="medium" top="small" component="div">
          {moreLink}
        </Cushion>
      </div>
    );
  }

  return (
    <div>
      <Rectangle radius="8px" style={{ overflow: 'hidden' }} component="div">
        {content}
      </Rectangle>
      {moreLink}
    </div>
  );
};

HomepageTable.propTypes = {
  data: PropTypes.shape({
    teamId: PropTypes.number,
    teamName: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.shape()),
  }),
  club: CLUB_PROP_TYPES.isRequired,
};

HomepageTable.defaultProps = {
  data: {},
};

export default HomepageTable;
